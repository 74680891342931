<template>
  <div data-test="google-login-button">
    <dimmer :active="loading" class="google-button">
      <div id="g_id_signin"/>
    </dimmer>
    <div v-if="invalid" class="invalid-feedback d-block text-center mt-2">You are not allowed to login to this portal</div>
  </div>
</template>

<script>

import {auth} from '@/services';

export default {
  name: 'GoogleLoginButton',
  data() {
    return {
      params: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: this.handleGoogleCallback,
        context: 'signin',
        ux_mode: 'popup',
        auto_prompt: true,
        itp_support: true,
      },
      buttonParams: {
        type: 'standard',
        shape: 'rectangular',
        theme: 'outline',
        text: 'signin_with',
        size: 'large',
        logo_alignment: 'left',
        width: 250,
      },
      invalid: false,
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    const googleAuthScript = document.createElement('script');
    googleAuthScript.src = 'https://accounts.google.com/gsi/client';
    googleAuthScript.async = true;
    googleAuthScript.defer = true;

    document.head.appendChild(googleAuthScript);

    // usually if the script and SPA is loaded and user got redirected here the load is already triggered so we only need to retrigger the initializeGoogle
    if (window.google?.accounts?.id) {
      this.initializeGoogle();
    }
    else {
      // listen and wait for the script to load
      googleAuthScript.addEventListener('load', this.initializeGoogle);
    }
  },
  methods: {
    initializeGoogle() {
      window.google.accounts.id.initialize(this.params);
      window.google.accounts.id.prompt(this.handleNotification);
      window.google.accounts.id.renderButton(document.getElementById('g_id_signin'), this.buttonParams);
      this.loading = false;
    },
    handleNotification(notification) {
      this.invalid = false;
      if (notification.isSkippedMoment() && notification.getSkippedReason === 'issuing_failed') {
        this.invalid = true;
      }
    },
    async handleGoogleCallback({credential}) {
      this.invalid = false;
      this.loading = true;

      try {
        const {customer, api_key} = await auth.login({token: credential, grantType: 'admin'});
        await this.$store.dispatch('auth/login', {customer, api_key});
        this.$router.push(this.$route.query.redirect || '/');
        this.loading = false;
      }
      catch (ex) {
        if (ex.response && ex.response.status === 401) {
          this.invalid = true;
        }
        else { throw ex; }
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.google-button {
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 44px;
}

</style>
