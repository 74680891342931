<template>
  <div class="page-single">
    <div class="container">
      <div class="row">

        <div class="col col-login mx-auto">
          <div class="text-center mb-6">
            <img
              src="@/assets/logo.png"
              class="h-6"
              alt="logo"
            >
          </div>
          <div class="card">
            <h4 class="mb-0 text-center pt-6">Sign in to continue</h4>
            <form
              v-if="environment !== 'production'"
              ref="form"
              class="needs-validation mb-0"
              novalidate
              @submit.prevent="submit">
              <div class="card-body px-6 pb-0">
                <div class="form-group">
                  <label data-test="label-email" class="form-label">Email address</label>
                  <input
                    v-model="email"
                    type="email"
                    :class="`form-control ${validator.errors().has('email') ? 'is-invalid' : ''}`"
                    placeholder="Enter email"
                    data-test="txt-email"
                    required
                    autocomplete="email">
                  <div v-if="validator.errors().has('email')" class="invalid-feedback">{{validator.errors().first('email')}}</div>
                </div>
                <div class="form-group">
                  <label data-test="label-password" class="form-label">
                    Password
                    <a :href="forgotPasswordUrl" class="float-right small">I forgot my password</a>
                  </label>
                  <input
                    v-model="password"
                    type="password"
                    :class="`form-control ${validator.errors().has('password') ? 'is-invalid' : ''}`"
                    placeholder="Password"
                    data-test="txt-password"
                    required
                    autocomplete="current-password">
                  <div v-if="validator.errors().has('password')" class="invalid-feedback d-block"> {{validator.errors().first('password')}}</div>
                  <div v-else-if="invalid" class="invalid-feedback d-block">Your email address or password was incorrect</div>
                </div>
                <div class="form-group">
                  <label class="custom-control custom-checkbox">
                    <input
                      v-model="rememberMe"
                      type="checkbox"
                      data-test="rememberme-checkbox"
                      class="custom-control-input">
                    <span data-test="label-rememberme" class="custom-control-label">Remember me</span>
                  </label>
                </div>
                <div class="form-footer mt-0">
                  <button
                    type="submit"
                    class="btn btn-primary btn-block"
                    :disabled="submitting">
                    Sign in
                  </button>
                </div>
              </div>
            </form>
            <google-login-button class="mt-4 mb-6"/>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import GoogleLoginButton from '@/components/GoogleLoginButton';
import {auth} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';

export default {
  components: {
    GoogleLoginButton,
  },
  mixins: [
    submitting,
  ],
  data() {
    return {
      email: this.$route.query.email || process.env.VUE_APP_TEST_EMAIL,
      invalid: false,
      password: process.env.VUE_APP_TEST_PASSWORD,
      rememberMe: false,
      validator: this.$bodyValidator,
      environment: process.env.VUE_APP_ENV,
    };
  },
  computed: {
    forgotPasswordUrl() {
      return `${process.env.VUE_APP_WEBSITE}/forgot-password?email=${this.email ? encodeURIComponent(this.email) : ''}`;
    },
  },
  methods: {
    async submit() {
      this.invalid = false;

      this.validator
        .setData({
          email: this.email,
          password: this.password,
          rememberMe: this.rememberMe,
        })
        .setRules({
          email: 'required',
          password: 'required',
          rememberMe: 'strict|boolean',
        });

      if (!this.validator.validate()) {
        return;
      }

      await this.submitValidatedForm(this.$refs.form, async () => {
        try {
          const {customer, api_key} = await auth.login({email: this.email, password: this.password, rememberMe: this.rememberMe});
          await this.$store.dispatch('auth/login', {customer, api_key});
          this.$router.push(this.$route.query.redirect || '/');
        }
        catch (ex) {
          if (ex.response && ex.response.status === 401) {
            this.invalid = true;
          }
          else { throw ex; }
        }
      });
      if (this.invalid) { this.$refs.form.classList.add('was-validated'); }
    },
  },
};
</script>
